<template>
     <section>

  
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">

                  <router-link to="/produits">
              <span class="float-right btn btn-info">
                retour
              </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="updaterproduit()">
                 <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first"
                        >Référence <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="reference"
                        type="numper"
                        class="form-control"
                        placeholder=""
                        id="first"
                      />
                      <span class="text-danger" id="produit_validation" style="display:none">Produit existe déja</span>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Catégorie <span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="exampleSelect"
                        v-model="categorie"
                      >
                        <option value="produit">Produit</option>
                        <option value="echantillon">Echantillon</option>
                        <option value="marketing">Marketing</option>
                      </select>
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div v-show="categorie == 'produit'" class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Prix <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="prix"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Désignation<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="designation"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Lot<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="lot"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Quantités minimal
                        <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="quantites_minimal"
                        type="number"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>

                  <div class="form-check form-check-inline ">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="unite"
                      v-model="type"
                    />
                    <label class="form-check-label" for="inlineRadio1"
                      >Par unité</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="carton"
                      v-model="type"

                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Par carton</label
                    >
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Coéficient<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="coeficient"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Num_série<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="num_serie"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first"
                        >Fournisseur <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="fournisseur"
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Date de construction
                        <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="date_construction"
                        type="date"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Date de peremption
                        <span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="date_peremption"
                        type="date"
                        class="form-control"
                        placeholder=""
                        id="last"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="last"
                        >Image <span class="obligatoire">*</span></label
                      >
                      <input @change="uploadFile" type="file" ref="file" />
                    </div>
                  </div>
                     <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Marque<span class="obligatoire">*</span>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="marque"
                          required
  
                        >
                          <option
                            v-for="user in marques"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>
                       <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Societe <span class="obligatoire">*</span>
                        </label>
  
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="societe"
                          required
  
                        >
                          <option
                            v-for="user in societes"
                            :key="user"
                            v-bind:value="user"
                          >
                            {{ user }}
                          </option>
                        </select>
                      </div>
                    </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="first"
                        >Description <span class="obligatoire">*</span>
                      </label>
                      <textarea
                        v-model="description"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <!--  col-md-6   -->
                  </div>

              <button type="submit" class="btn btn-primary" >Modifier Produit</button>
              </form>
            </div>
          </div>
          <div
            class="alert"
            style="
              display: none;
              background-color: #ff0033;
              color: white;
              font-weight: bold;
              padding-top: 10px;
              padding-bottom: 10px;
              box-shadow: 0px 2px 1px #e0002d;
              cursor: default;
            "
          >
            Error: il faut remplir tous les champs
            <a
              href="javascript:void(0)"
              style="
                -webkit-transition: 0.2s;
                -moz-transition: 0.2s;
                -o-transition: 0.2s;
                -ms-transition: 0.2s;
                transition: 0.2s;
                text-decoration: none;
                color: #fff;
                background-color: #ff0033;
                border: 1px solid #fff;
                padding-bottom: 5px;
                padding-top: 5px;
                padding-right: 10px;
                padding-left: 10px;
                border-radius: 3px;
                margin: 5px;
                margin-right: 10px;
                margin-top: 0;
                font-size: 10px;
                float: right;
              "
              class="x"
              >cacher</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { HTTP } from '@/axios';
import moment from 'moment';
export default {
     data() {
    return {
       marques: ['foo', 'bar', 'fizz', 'buzz'],
        societes:['PROCHIDIA','ARGANIA'],
      file:"",
         prix: "",
      reference: "",
      description: "",
      quantites: "",
        marque: "",
      societe: "",
     
      quantites_minimal:"",
      categorie:"",
    
      fournisseur:"",
      designation:"",
      prix:"",
      date_construction:"",
      date_peremption:"",
      
      
      lot:"",
      num_serie:"",
      type:"",
      coeficient:"",
      
    }
  },
   created: function () {
     this.getProductById();
  
  },
    methods: {
       uploadFile () {
        this.file = this.$refs.file.files[0];
        console.log(this.file);
        
        },
        getProductById(){
            
            HTTP.get('produits/getproduitsbyid/'+this.$route.params.id)
         .then(res => {
         
         
          this.prix=res.data.prix;
          this.societe=res.data.societe;
          this.marque=res.data.marque;
          this.description=res.data.description;
          this.reference=res.data.reference;

          this.quantites=res.data.quantites;
          this.quantites_minimal=res.data.quantites_minimal;
          this.categorie=res.data.categorie;
          this.fournisseur=res.data.fournisseur;
          this.designation=res.data.designation;
          this.prix=res.data.prix;

          this.lot=res.data.lot;
          this.num_serie=res.data.num_serie;
          this.type=res.data.type;
          this.coeficient=res.data.coeficient;

          this.date_construction = moment(res.data.date_construction).format(
            "YYYY-MM-DD"
          );

          this.date_peremption = moment(res.data.date_peremption).format(
            "YYYY-MM-DD"
          );



        }, err => {
           this.error = err.response.data.error
        })
        },
    updaterproduit() {
      if( this.categorie!="produit"){
        this.prix=""
      }
      const formData = new FormData();
        formData.append('photo', this.file)
        formData.append('prix', this.prix)
          formData.append("marque", this.marque);
      formData.append("societe", this.societe);
        formData.append('_id', this.$route.params.id)
        formData.append('reference', this.reference)
        formData.append('description', this.description)
        formData.append('quantites', this.quantites)
        formData.append('quantites_minimal', this.quantites_minimal)
        formData.append('categorie', this.categorie)
        formData.append('fournisseur', this.fournisseur)
        formData.append('date_construction', this.date_construction)
        formData.append('designation', this.designation)
        formData.append('date_peremption', this.date_peremption)

      formData.append("lot", this.lot);
      formData.append("num_serie", this.num_serie);
      formData.append("type", this.type);
      formData.append("coeficient", this.coeficient);

      // let produit = {
      // prix: this.prix,
      // reference: this.reference,
      // description: this.description,
      // quantites: this.quantites,
      //  quantites_minimal:this.quantites_minimal,
      // categorie:this.categorie,
      // fournisseur:this.fournisseur,
      // designation:this.designation,
      // prix:this.prix,
      // date_construction:this.date_construction,
      // date_peremption:this.date_peremption,
      // _id:this.$route.params.id

      // };
       
       HTTP.put('produits/modifierproduit', formData)
         .then(res => {
         
           if (res.status === 200) {
                    Toast.fire({
                        icon: "success",
                        title: "produit modifié"
           });
            this.$router.push('/produits');
          
           }
        }, err => {
          
          this.error = err.response.data;
            if(this.error.title=='required'){
              $(".alert").slideDown(400);
          $(".x").click(function () {
            $(".alert").slideUp("slow");
          });
            }
            else if(this.error.title=='exist'){

   $('#produit_validation').show()

setTimeout(function() {
     $('#produit_validation').fadeOut('fast');
}, 5000); 



            }
         


          





        })
    },
 
  }
  
}
</script>